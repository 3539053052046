<template>
  <section class="section-flashdeal--default ps-home--block" v-if="this.products.length">
    <div :class="$isMobile() ? 'container-fluid-sy-m' : 'container-fluid-sy'">
      <div class="ps-block__header">
        <h3 class="ps-block__title"><i class="icon-power"></i>Flash Deals</h3>
        <router-link class="ps-block__view" :to="{ name: 'products', query: { category_id: 2 } }">
          View all
          <i class="icon-chevron-right"></i>
        </router-link>
      </div>

      <div class="flashdeal--content mx--5" v-if="isLoadedFlashDeal">
        <carousel
          :itemsToShow="itemsToShow"
          :itemsToScroll="1"
          :autoplay="6000"
          :transition="1000"
          :wrapAround="false"
          snap-align="start"
          :breakpoints="breakpoints"
          class="owl-carousel owl-loaded owl-drag"
        >
          <slide v-for="(product, index) in products" :key="index" class="carousel-flash-deals" style="padding:0 5px">
            <ProductDefault :product="product"/>
          </slide>

          <template #addons>
            <div class="owl-nav">
              <navigation />
            </div>
          </template>
        </carousel>
      </div>

      <div v-else>
        <div class="row">
          <div class="col-6 col-sm-2" v-for="(c, index) in ($isMobile() ? 2 : 6)" :key="index">
            <ProductSkelton/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import ProductDefault from "@/views/Components/ProductDefault";
import ProductSkelton from "@/views/Components/ProductSkelton";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FlashDeals",

  components: {
    Carousel,
    Slide,
    Navigation,
    ProductDefault,
    ProductSkelton
  },

  data() {
    return {
      itemsToShow: 6,
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        // 300px and up
        300: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 600px and up
        600: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        // 800px and up
        800: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
        // 1024 and up
        1024: {
          itemsToShow: 6,
          snapAlign: 'start',
        },
      },
    };
  },

  mounted() {
    if (!this.products.length) {
      this.fetchFlashDeals();
    }
  },

  computed: {
    ...mapGetters("home", ["flashDealsProducts",'isLoadedFlashDeal']),

    products() {
      return this.flashDealsProducts;
    },
  },

  methods: {
    ...mapActions("home", ["fetchFlashDeals"]),
  },
};
</script>

<style>
.carousel-flash-deals {
  display: inline-block;
}
.section-flashdeal--default .flashdeal--content .owl-carousel{
  height: auto;
}
section.section-flashdeal--default.ps-home--block {
  background: #fff;
  padding: 15px 0 0;
  margin-bottom: 10px;
}
</style>
