<template>
  <div class="categories--block">
    <div class="ps-block__header">
      <h3 class="ps-block__title">
        <router-link class="categories__title" :to="{ name: 'products', query: { category_id: banner.category_id } }">{{ banner.category_name }}</router-link>
      </h3>
      <router-link class="ps-block__view" :to="{ name: 'products', query: { category_id: banner.category_id } }">
        View all <i class="icon-chevron-right"></i>
      </router-link>
    </div>

    <div class="categories__content">
      <div class="categories__products">
        <div class="row mx--5" v-if="!isLoadingPro">
          <div class="col-6 col-md-3 col-lg-2" style="padding:0 5px" v-for="(product, index) in banner.products" :key="index">
            <ProductDefault :product="product" />
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-sm-2" v-for="(c, index) in $isMobile() ? 1 : 6" :key="index">
            <ProductSkelton :buttons="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import ProductDefault from "@/views/Components/ProductDefault";
import ProductSkelton from "../../Components/ProductSkelton.vue";
import { mapActions } from 'vuex';

export default {
  props: ["banner"],
  components: {
    ProductDefault,
    ProductSkelton,
  },
  data() {
    return {
      isLoadingCat: false,
      isLoadingPro: false,
      category: {},
      products: [],
    };
  },
  mounted() {
    if(!this.banner.productsLoaded){
      this.fetchProducts();
    }
  },
  computed: {},
  methods: {
    ...mapActions('home', ['attachHomeBanner']),

    fetchProducts() {
      this.isLoadingPro = true;
      var filters = {
        category_id: this.banner.category_id,
        limit: 6,
      };
      ApiService.query("products", { params: filters })
        .then(({ data }) => {
          const d = this.banner
          d.productsLoaded = true
          d.products = data.data
          this.attachHomeBanner(d)
          this.isLoadingPro = false;
        })
        .catch(() => {
          this.isLoadingPro = false;
        });
    }
  },
};
</script>
<style scoped>
.ps-product--standard {
  border: 1px solid rgb(219, 219, 219);
}
.content {
  padding: 5px;
}
.categories--block .ps-block__view {
  padding: 0px 0px;
  cursor: pointer;
}
.categories__title {
  font-weight: bolder;
  color: #ff7204;
}
</style>
