<template>
  <section class="section-categories--default-s ps-home--block" ref="categories">
    <div :class="$isMobile() ? 'container-fluid-sy-m' : 'container-fluid-sy'">
      <template v-if="!isLoading">
        <div v-for="(banner, index) in banners" :key="index" class="category-block">
          <fresh-food-block :banner="banner"></fresh-food-block>
          <div class="home-banner" v-if="banner.banner_status && banner.image">
            <a :href="banner.url">
              <img :src="banner.image" :alt="banner.category_name + ' banner'"/>
            </a>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="bNum in 5" :key="bNum" style="margin-bottom:80px">
          <div class="mg-t-10">
            <Skeletor height="25" width="100" />
          </div>
          <div class="mg-t-10"></div>
          <div class="row mg-t-10">
            <div class="col-sm-2" v-for="pNum in 6" :key="pNum">
              <product-skelton :buttons="false" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import FreshFoodBlock from "./FreshFoodBlock";
import { mapActions, mapGetters } from "vuex";
import ProductSkelton from "../../Components/ProductSkelton.vue";
import { Skeletor } from "vue-skeletor";

export default {
  name: "FreshFoods",

  components: {
    FreshFoodBlock,
    ProductSkelton,
    Skeletor,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters("home", ["banners"]),
  },

  mounted() {
    if(!this.banners.length){
      this.onFetchBanners();
    }
  },

  methods: {
    ...mapActions("home", ["fetchBanners"]),

    onFetchBanners() {
      this.isLoading = true;
      this.fetchBanners().then(() => {
          this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
<style lang="scss">
  .home-banner {
    text-align: center;
    background: #fff;
    margin: 10px 0;
    box-shadow: 1px 6px 12px 0px #00000012;
    border-radius: 10px;
    overflow: hidden;
}
</style>