<template>
  <button class="open-button" @click="openForm()">
    <i class="fa fa-whatsapp fa-2x" style="color:#ffffff"></i>
  </button>

  <!-- <div class="chat-popup" id="myForm">
    <form action="/action_page.php" class="form-container">
      <h4>Chat with us</h4>

      <label for="msg"><b>Message</b></label>
      <textarea placeholder="Type message.." name="msg" required></textarea>

      <button type="submit" class="btn">Send</button>
      <button type="button" class="btn cancel" @click="closeForm()">
        Close
      </button>
    </form>
  </div> -->
</template>
<script>
export default {
  name: "Home",

  methods: {
    openForm:function () {
      // document.getElementById("myForm").style.display = "block";
          window.open("https://wa.me/+19733410582?text=Hi there!", "_blank");
      
    },

    closeForm() {
      document.getElementById("myForm").style.display = "none";
    },
  },
};
</script>
<style>
@import "../../assets/css/chat.css";
</style>