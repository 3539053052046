<template>
  <main class="no-main bg-light-gray-force">
    <!-- Slider -->
    <Slider />

    <!-- Promotion -->
    <Promotion />

    <!-- FlashDeals Section -->
    <FlashDeals />

    <!-- FreshFoods Section -->
    <FreshFoods />

    <ChatBox/>
  </main>
</template>


<script>
import Slider from "./Components/Slider";
import Promotion from "./Components/Promotion";
import FlashDeals from "./Components/FlashDeals";
import FreshFoods from "./Components/FreshFoods";
import ChatBox from '../Components/ChatBox';

export default {
  name: "Home",

  components: {
    Slider,
    Promotion,
    FlashDeals,
    FreshFoods,
    ChatBox,
  },
};
</script>
