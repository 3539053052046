<template>
	<div class="section-slide--default">
		<div v-if="isLoadingSliders" class="slide-loading">
			<loading v-model:active="isLoading" :is-full-page="fullPage" background-color="#f7f7f7" color="#ff7200"></loading>
		</div>
		<carousel
			:items-to-show="1"
			:autoplay="6000"
			:transition="1000"
			class="owl-carousel owl-loaded owl-drag"
			v-else-if="sliders.length"
		>
			<template	v-for="(slider, index) in deviceSliders" :key="index">
					<slide>
						<div class="ps-banner">
							<img
								:src="slider.image_url"
								alt="alt"
							/>
							<div class="ps-content">
								<div class="container">
									<div class="ps-content-box">
										<div
											class="ps-subtitle"
											v-html="slider.content"
										></div>
									</div>
								</div>
							</div>
						</div>
					</slide>
</template>

			<template #addons>
				<div class="owl-nav">
					<navigation />
				</div>
			</template>
		</carousel>
	</div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "Slider",
	components: {
		Carousel,
		Slide,
		Navigation,
	},
	data() {
		return {
			isLoading: true,
      fullPage: false,
		};
	},
	mounted() {
		if (this.sliders && !this.sliders.length) {
			this.fetchSliders();
		}
	},
	computed: {
		...mapGetters("home", ["isLoadingSliders", "sliders"]),
		deviceSliders () { 
			if(this.$isMobile()) {
				return this.sliders.filter(a=>a.title == 'Mobile')
			}
			return  this.sliders.filter(a=>a.title !== 'Mobile')
		}
	},
	methods: {
		...mapActions("home", ["fetchSliders"]),
	},
};
</script>

<style lang="scss" scoped>
.desktop-only {
	width: 2000px;
}
.slide-loading {
  min-height: 300px;
}
</style>