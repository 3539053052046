<template>
   <div class="bg-white" style="margin-bottom:10px">
        <Skeletor height="250" />

        <div class="pd-10">
        <div class="mg-t-10">
            <Skeletor height="10" width="80px"/>
        </div>

        <div class="mg-t-10">
            <Skeletor height="15"/>
        </div>
        <div class="mg-t-10 " v-if="buttons">
            <div class="row">
            <div class="col-sm-5"></div>
            <div class="col-sm-7">
                <Skeletor height="35"/>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>

import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";
export default {
    props:{
        buttons:{
            type:Boolean,
            default:true
        }
    },
    components:{Skeletor}
}
</script>