<template>
  <div class="ps-promotion--default">
    <div class="container1">
      <div class="row mx--5" v-if="!isLoading">
        <div class="col-6 col-lg-3" 
          v-for="(banner, index) in top_banners" :key="index">
          <a href="#">
            <img :src="banner.images[0]" alt="alt" />
          </a>
        </div>
      </div>
      <div v-else class="row mx--5">
        <div class="col-6 col-lg-3">
            <Skeletor height="200" />
        </div>
        <div class="col-6 col-lg-3">
            <Skeletor height="200" />
        </div>
        <div class="col-6 col-lg-3">
            <Skeletor height="200" />
        </div>
        <div class="col-6 col-lg-3">
            <Skeletor height="200" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";

export default {
  name: "Promotion",

  data() {
    return {
      isLoading: false,
    };
  },

  components: {
    Skeletor,
  },

  computed: {
    ...mapGetters("home", ["top_banners"]),
  },

  mounted() {
    if(!this.top_banners.length){
      this.onFetchBanners();
    }
  },

  methods: {
    ...mapActions("home", ["fetchTopBanners"]),

    onFetchBanners() {
      this.isLoading = true;
      this.fetchTopBanners()
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
}
</script>